.back-ipn{
    background-color: white !important;
    /*color: white !important;*/
}

.pt{
    padding-top: 2%;
}

.back-check{
    background-color: red !important;
    color: grey !important;
}

.ipn{
    background-color: #4b0024 !important;
}