.col-ipn{
  background-color:   #4b0024
}
.pt{
    margin-top: 10px;
}

.sub{
    text-align: left;
    line-height: 15px;
}

span{
    font-size: 0.7rem;
}

.father{
    display: flex;
    align-items: center;
}


.line {
    padding-top: 10px;
    margin: 0 auto;
    border-bottom: 3px solid #4b0024;
    width: 100%;
}


.txt-ipn{
    color:  #4b0024;

}

.txt-description{

    padding: auto 40px;
    color:  #4b0024;
    font-size: 1.5rem;
}

.ft-but{

    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px 30px;
}

.ft-but a{
    color: white;
    text-decoration: none;
}

.btn-ipn{
    color: white;
    background-color: #4b0024;
    margin:10px 30px;
}

 .lin{
     margin: 10px 10px;
 }
