.navbarF{

    display: flex;

    justify-content: space-evenly;

}


@media (max-width: 1000px) {
 

    .navbarF{
        display: inline-block;
    }


}