.txt-ipn{
    color: #4b0024;
}

.estado{
    text-align: left;
    /*background-color: yellow;*/
    font-size: 1.1rem;
}

.btn-ipn{
    background-color: #4b0024;
}

.fath{

    display: flex;
    align-items: baseline;

}

.btn-ipn:hover {
    background-color: rgb(255, 11, 108);
}